import { JSX, ReactNode } from 'react';
import Logo from '../forloopcowboy/Logo.tsx';

interface LandingPageProps {
  children: ReactNode;
}

export function LandingPage(props: LandingPageProps): JSX.Element {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="text-white">
        <div className="flex flex-col items-center">
          <Logo className="m-auto aspect-square w-24" />
          <h1 className="font-riot text-xl">forloopcowboy</h1>
        </div>
        <div className="flex justify-center gap-0 md:gap-1 lg:gap-4">{props.children}</div>
      </div>
    </div>
  );
}
