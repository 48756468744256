import { JSX, MouseEventHandler, ReactNode } from 'react';
import * as React from 'react';
import { IconClasses } from '../styling/IconClasses';
import { classNames } from '../styling/StylingUtils';

export type HeroIcon = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>;

export type GenericIcon = (props: { className: string }) => JSX.Element;

interface IconButtonProps {
  Icon: HeroIcon | GenericIcon;
  size: keyof typeof IconClasses;
  children?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
}

export function IconButton(props: IconButtonProps): JSX.Element {
  const { Icon, size, className } = props;

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        'group relative flex h-fit w-fit flex-col items-center justify-center gap-1 rounded-xl bg-transparent p-1 text-white hover:bg-gray-900 md:p-3',
        className,
      )}
    >
      <div
        className={classNames(
          props.active ? 'bg-gray-800' : 'bg-transparent',
          'rounded-full p-2 transition-all duration-300 group-hover:bg-gray-800',
        )}
      >
        <Icon className={classNames(IconClasses[size], props.active ? 'text-blue-400' : '')} />
      </div>
      <div
        className={classNames(
          'absolute -bottom-6 rounded-xl px-2 py-1 text-transparent transition-all duration-300 group-hover:text-white',
        )}
      >
        {props.children}
      </div>
    </button>
  );
}
