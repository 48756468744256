import { NavLink, useMatch } from 'react-router-dom';
import { IconButton } from '../components/IconButton';
import {
  CameraIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  EnvelopeIcon,
  UserIcon,
  BookOpenIcon,
  HomeIcon,
} from '@heroicons/react/24/solid';
import React, { ElementType, Fragment } from 'react';
import { GenericComponentProps } from '../utils/GenericComponents';
import { IconClasses } from '../styling/IconClasses';
import { classNames } from '../styling/StylingUtils';
import useQueryTags from '../hooks/useQueryTags.ts';
import usePermissions from '../hooks/UsePermission.tsx';

export default function HeaderLinks<Wrapper extends ElementType>({
  as,
  size = 'MD',
  ...props
}: GenericComponentProps<Wrapper> & { size?: keyof typeof IconClasses }) {
  const { canWrite } = usePermissions();
  const Wrapper = as || Fragment;
  const wrapperProps =
    Wrapper !== Fragment
      ? {
          className: classNames(
            'sticky z-10 top-0 flex h-fit w-full items-center justify-center pt-5 bg-gray-900 bg-opacity-90',
            props.className,
          ),
          ...props,
        }
      : {};

  const queryTags = useQueryTags();
  const homeMatch = useMatch({ path: '/' });
  const crewMatch = useMatch({ path: '/crew/*' });
  const articleMatch = useMatch({ path: '/articles/*' });
  const demoMatch = useMatch({ path: '/demos/*' });
  const dashboardMatch = useMatch({ path: '/dashboard/*' });
  const contactMatch = useMatch({ path: '/contact/*' });
  const photographyMatch = articleMatch && queryTags.searchTags.map((s) => s.toLowerCase()).includes('photography');

  return (
    <Wrapper {...wrapperProps}>
      {!homeMatch && (
        <NavLink to={`/`}>
          <IconButton Icon={HomeIcon} size={size}>
            Home
          </IconButton>
        </NavLink>
      )}
      <NavLink to={`../demos`}>
        <IconButton active={Boolean(demoMatch)} Icon={CommandLineIcon} size={size}>
          Code
        </IconButton>
      </NavLink>
      <NavLink to="../articles">
        <IconButton active={Boolean(articleMatch) && !photographyMatch} Icon={BookOpenIcon} size={size}>
          Portfolio
        </IconButton>
      </NavLink>
      <NavLink to="../articles?tags=Photography">
        <IconButton active={Boolean(photographyMatch)} Icon={CameraIcon} size={size}>
          Photos
        </IconButton>
      </NavLink>
      <NavLink to="../crew">
        <IconButton active={Boolean(crewMatch)} Icon={UserIcon} size={size}>
          Crew
        </IconButton>
      </NavLink>
      <NavLink to="../contact">
        <IconButton active={Boolean(contactMatch)} Icon={EnvelopeIcon} size={size}>
          Contact
        </IconButton>
      </NavLink>
      {canWrite && (
        <NavLink to="../dashboard">
          <IconButton active={Boolean(dashboardMatch)} Icon={Cog6ToothIcon} size={size}>
            Dashboard
          </IconButton>
        </NavLink>
      )}
    </Wrapper>
  );
}
