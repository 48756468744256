import { Outlet } from 'react-router-dom';
import SupabaseClientProvider from '../data/SupabaseClientProvider';
import { supabaseKey, supabaseUrl } from '../data/SupabaseIntegration';
import { SupabaseAuthenticationProvider, useSupabaseAuthContext } from '../data/SupabaseAuthentication';
import { Bounce, ToastContainer } from 'react-toastify';
import { getLang, LocaleContext } from '../data/Locale';
import { useMemo } from 'react';
import { PermissionsContextProvider } from '../hooks/UsePermission';
import ErrorPanel from '../components/ErrorPanel';
import { ErrorBoundary } from 'react-error-boundary';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import useOperatingSystem, { OperatingSystem } from '../hooks/UseOperatingSystem.ts';
import { Helmet } from 'react-helmet';

export function AppRoot() {
  const locale = useMemo(getLang, navigator?.languages);
  const os = useOperatingSystem();

  return (
    <SupabaseClientProvider url={supabaseUrl} supabaseKey={supabaseKey}>
      <LocaleContext.Provider value={locale}>
        <SupabaseAuthenticationProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            transition={Bounce}
          />
          <PermissionsContextProvider>
            <ErrorBoundary FallbackComponent={ErrorPanel}>
              <Helmet defaultTitle="FOR_LOOP_COWBOY" titleTemplate="%s | FOR_LOOP_COWBOY" />
              <Outlet />
              <div className="w-full bg-gray-900">
                <hr className="border border-gray-800" />
                <div className="flex w-full items-center justify-center bg-gray-900 p-5 text-gray-400">
                  <BottomLinks />
                </div>
              </div>
              {os !== OperatingSystem.MAC && (
                // Windows scrollbars look ugly as fuck. Mac auto hides them
                // So I set a nice custom style for non mac OS, but keep the default look on MacOS
                <style>
                  {`
                  /* width */
                  ::-webkit-scrollbar {
                      width: 5px;
                      opacity: 0;
                  }
                  
                  /* Track */
                  ::-webkit-scrollbar-track {
                      background: #27292a;
                  }
                  /* Handle */
                  ::-webkit-scrollbar-thumb {
                      background: #4d5357;
                      border-radius: 4px;
                  }
                  /* Handle on hover */
                  ::-webkit-scrollbar-thumb:hover {
                      background: #2a427e;
                  }
                  `}
                </style>
              )}
            </ErrorBoundary>
          </PermissionsContextProvider>
        </SupabaseAuthenticationProvider>
      </LocaleContext.Provider>
    </SupabaseClientProvider>
  );
}

function BottomLinks() {
  const auth = useSupabaseAuthContext();

  return (
    <button
      className="flex items-center justify-center gap-2 text-gray-700 hover:text-blue-600 hover:underline"
      onClick={auth.openAuth}
    >
      <UserGroupIcon className="aspect-square h-5 shrink-0" />
      <span>{auth.session ? 'View account' : 'Sign in'}</span>
    </button>
  );
}
