import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import { DefaultComponents } from '../DefaultComponents.tsx';
import Markdown from 'react-markdown';
import { Fragment } from 'react';
import GithubIcon from '../../icons/GithubIcon.tsx';
import AppleIcon from '../../icons/AppleIcon.tsx';
import WindowsIcon from '../../icons/WindowsIcon.tsx';

export type MarkdownElement = {
  type: 'markdown';
  value: string;
};

export type SpecialElement = {
  type: 'special';
  tag: string;
  children: string;
  props: Record<string, string>;
};

export type AnyArticleElement = MarkdownElement | SpecialElement;

export type ArticleElementProps<E = AnyArticleElement> = { element: E };

export function ArticleElement(props: ArticleElementProps) {
  switch (props.element.type) {
    case 'markdown':
      return (
        <Markdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSanitize]}
          components={DefaultComponents}
          className="w-full py-3 text-gray-400"
        >
          {props.element.value}
        </Markdown>
      );
    case 'special':
      return <SpecialElement element={props.element} />;
  }
}

/**
 * Renders a special element. See definition for list of supported elements.
 * @param props
 * @constructor
 */
function SpecialElement(props: { element: SpecialElement }) {
  // SUPPORTED ELEMENTS
  if (isIconButton(props.element)) {
    return (
      <DefaultComponents.a
        target="_blank"
        href={props.element.props.to}
        className="flex items-center justify-center gap-2 self-stretch rounded-md bg-gray-800 px-2 py-1 text-lg text-white visited:!text-white hover:cursor-pointer hover:bg-gray-700"
      >
        <Icon name={props.element.props.icon} className="aspect-square h-6 shrink-0" />
        {props.element.children}
      </DefaultComponents.a>
    );
  }

  console.warn(`Unsupported special element: ${props.element.tag}`);
  return <Fragment />;
}

function Icon(props: { name: string; className: string }) {
  switch (props.name.toLowerCase()) {
    case 'github':
      return <GithubIcon className={props.className} />;
    case 'apple':
      return <AppleIcon className={props.className} />;
    case 'windows':
      return <WindowsIcon className={props.className} />;
    default:
      console.warn(`Unsupported icon: ${props.name}`);
      return <Fragment />;
  }
}

//// SUPPORTED ELEMENTS ////

/**
 * Icon button. Links to a URL and displays an icon.
 * @example <IconButton to="https://www.myWebsite.com" icon="apple">My Button</IconButton>
 * @param element
 */
function isIconButton(element: AnyArticleElement): element is {
  type: 'special';
  tag: 'IconButton';
  children: string;
  props: {
    icon: string;
    to: string;
  };
} {
  return (
    element.type === 'special' &&
    element.tag === 'IconButton' &&
    'icon' in element.props &&
    'to' in element.props &&
    'children' in element &&
    typeof element.props.icon === 'string' &&
    typeof element.props.to === 'string' &&
    typeof element.children === 'string'
  );
}
