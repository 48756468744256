import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AppRoot } from './AppRoot';
import React from 'react';
import { ArticleHome } from './ArticleHome';
import { AppHome } from './AppHome';
import { ArticleView } from './ArticleView';
import { DemoHome } from './DemoHome';
import ListInputDemo from '../forloopcowboy/demos/ListInput.demo';
import MapPollDemo from '../forloopcowboy/demos/MapPoll.demo.tsx';
import ImageGalleryDemo from '../forloopcowboy/demos/ImageGallery.demo.tsx';
import CrewHome, { LeoGUserCard, LeoGUserLink } from './CrewHome.tsx';
import ColorPaletteDemo from '../forloopcowboy/demos/ColorPalette.demo.tsx';
import DashboardHome from './DashboardHome.tsx';
import PhotosHome from './PhotosHome.tsx';
import ViewSinglePhoto from './ViewSinglePhoto.tsx';
import AllPhotos from './AllPhotos.tsx';
import ContactHome from './ContactHome.tsx';
import { SingleMessage } from '../components/messages/SingleMessage.tsx';
import { NewContactForm } from '../components/messages/ContactForm.tsx';
import { MyMessages } from '../components/messages/MyMessages.tsx';
import PrivacyNotice from './PrivacyNotice.tsx';
import { transitions } from '../utils/TransitionHelpers.ts';
import { Transition } from '@headlessui/react';

export const appRoutes = createBrowserRouter([
  {
    element: <AppRoot />,
    children: [
      {
        path: '*',
        index: true,
        element: <AppHome />,
      },
      {
        path: 'dashboard',
        element: <DashboardHome />,
      },
      {
        path: 'photos',
        element: <PhotosHome />,
        children: [
          {
            index: true,
            element: <AllPhotos />,
          },
          {
            path: ':photoId',
            element: <ViewSinglePhoto />,
          },
        ],
      },
      {
        path: 'articles',
        element: <ArticleHome />,
        children: [
          {
            path: ':articleId',
            element: <ArticleView />,
          },
        ],
      },
      {
        path: 'demos',
        element: <DemoHome />,
        children: [
          {
            path: 'image-gallery',
            element: <ImageGalleryDemo />,
          },
          {
            path: 'list-input',
            element: <ListInputDemo />,
          },
          {
            path: 'wpi-map-poll',
            element: <MapPollDemo />,
          },
          {
            path: 'deterministic-color-palette',
            element: <ColorPaletteDemo />,
          },
        ],
      },
      {
        path: 'crew',
        element: <CrewHome />,
        children: [
          {
            index: true,
            element: <LeoGUserLink />,
          },
          {
            path: 'leogons',
            element: (
              <Transition show={true} appear={true} {...transitions.fadeSlow}>
                <LeoGUserCard />
              </Transition>
            ),
          },
        ],
      },
      {
        path: 'contact',
        element: <ContactHome />,
        children: [
          {
            index: true,
            element: <Navigate to="./new" />,
          },
          {
            path: 'new',
            element: <NewContactForm />,
          },
          {
            path: 'my-messages',
            element: <MyMessages />,
          },
          {
            path: 'my-messages/:messageId',
            element: <SingleMessage />,
          },
        ],
      },
      {
        path: 'privacy-notice',
        element: <PrivacyNotice />,
      },
    ],
  },
]);
