import { useSupabase } from './SupabaseClientProvider';
import { Session } from '@supabase/gotrue-js';
import React, { useEffect, useState } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { SupabaseClient } from '@supabase/supabase-js';
import Modal, { ModalSizes } from '../components/Modal';
import DynamicForm from '../components/DynamicForm';
import { DefaultComponents } from '../components/DefaultComponents';
import { ThemeMinimal } from '@supabase/auth-ui-shared';

export interface SupabaseAuthContext {
  session: Session | null;
  /** Opens authentication screen. */
  openAuth: () => void;
}

const Ctx = React.createContext<SupabaseAuthContext>({
  session: null,
  openAuth: () => {
    throw new Error('Authentication context not yet available.');
  },
});

export function SupabaseAuthenticationProvider(props: { children: React.ReactNode }) {
  const { getContext } = useSupabase();
  const supabase = getContext();
  const [isOpen, setIsOpen] = useState(false);
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    if (!supabase) {
      console.warn('Backend not available yet. ');
      return;
    }

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [supabase]);

  return (
    <>
      <Ctx.Provider value={{ session, openAuth: () => setIsOpen(true) }}>{props.children}</Ctx.Provider>
      <Modal maxWidth={ModalSizes.SM} isOpen={isOpen} closeModal={() => setIsOpen(false)}>
        <AuthPanel session={session} supabase={supabase} onSignOut={() => setSession(null)} />
      </Modal>
    </>
  );
}

function AuthPanel(props: { session: Session | null; supabase: SupabaseClient; onSignOut: () => void }) {
  const { session, supabase } = props;

  if (!session)
    return (
      <>
        <DefaultComponents.h1 className="text-lg text-gray-100">{getDescription(session)}</DefaultComponents.h1>
        <Auth supabaseClient={supabase} appearance={{ theme: ThemeMinimal }} providers={['github', 'google']} />
      </>
    );
  else if (session) {
    const user = session?.user;
    return (
      <div className="grid gap-2 p-5">
        <DefaultComponents.h1 className="text-lg text-gray-100">{getDescription(session)}</DefaultComponents.h1>
        <DynamicForm
          data={{
            id: user?.id,
            email: user?.email,
            created_at: user?.created_at,
          }}
        />
        <button
          onClick={() => {
            if (confirm('Are you sure you want to sign out?')) {
              supabase?.auth.signOut();
              props.onSignOut();
            }
          }}
          className="my-5 w-fit rounded-md bg-red-700 p-2 text-white transition-colors duration-200 ease-in-out hover:bg-red-800"
        >
          Sign out
        </button>
      </div>
    );
  }
}

function getDescription(session: Session | null) {
  if (!session) return 'Log in or join to contribute.';
  return 'Welcome back!';
}

export function useSupabaseAuthContext(): SupabaseAuthContext {
  return React.useContext(Ctx);
}
