import { matchPath, Outlet, useLocation, useMatch, useSearchParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { ArticleThumbnailLayout } from '../components/articles/ArticleThumbnail';
import { classNames } from '../styling/StylingUtils';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPanel from '../components/ErrorPanel';
import { renderDateCompact, useLocale } from '../data/Locale';
import HeaderLinks from './HeaderLinks';
import { HeroIcon } from '../components/IconButton.tsx';
import { CodeBracketIcon } from '@heroicons/react/16/solid';
import { Nullish } from '../data/Nullish.ts';
import { DefaultComponents } from '../components/DefaultComponents.tsx';
import { parseISO } from 'date-fns';
import _ from 'lodash';
import SearchBar from '../components/SearchBar.tsx';
import Fuse from 'fuse.js';
import { Helmet } from 'react-helmet';

export interface DemoArticleInfo {
  title: Nullish<string>;
  description: Nullish<string>;
  last_modified_at: Nullish<string>;
  created_at: Nullish<string>;
  id: string;
  icon?: HeroIcon;
}

export function isDemoArticle(article: unknown): article is DemoArticleInfo {
  return (
    typeof article === 'object' &&
    article !== null &&
    'title' in article &&
    'description' in article &&
    'last_modified_at' in article &&
    'id' in article &&
    typeof article.id === 'string'
  );
}

export const staticDemos: DemoArticleInfo[] = [
  {
    title: 'Color randomizer',
    description: "When you're too lazy to pick colors.",
    last_modified_at: new Date('2024-02-21').toISOString(),
    created_at: new Date('2024-02-21').toISOString(),
    id: 'deterministic-color-palette',
    icon: CodeBracketIcon,
  },
  {
    title: 'ImageGallery Component',
    description: 'Previewing and showcasing a set of images',
    last_modified_at: new Date('15 Feb 2024').toISOString(),
    created_at: new Date('15 Feb 2024').toISOString(),
    id: 'image-gallery',
    icon: CodeBracketIcon,
  },
  {
    title: 'ListInput Component',
    description: 'Who knows what this does?',
    last_modified_at: new Date('02/02/2024').toISOString(),
    created_at: new Date('02/02/2024').toISOString(),
    id: 'list-input',
    icon: CodeBracketIcon,
  },
  {
    title: 'WPI Through Many Eyes',
    description: 'A heatmap visualization of the Worcester Polytechnic Institute campus.',
    last_modified_at: new Date('Feb 26, 2020').toISOString(),
    created_at: new Date('Feb 26, 2020').toISOString(),
    id: 'wpi-map-poll',
  },
];

export function DemoHome() {
  const { h1: H1, h4: H2 } = DefaultComponents;
  const location = useLocation();
  const match = matchPath(
    {
      path: '/demos/:demoId',
    },
    location.pathname,
  );
  const demoId = match?.params.demoId;
  const [searchQuery, setSearchQuery] = useState('');

  const demos = useMemo(() => {
    const filteredDemos = searchQuery
      ? new Fuse(staticDemos, {
          keys: ['title', 'description'],
        })
          .search(searchQuery)
          .map((result) => result.item)
      : staticDemos;

    return _.sortBy(
      filteredDemos,
      ({ last_modified_at }) => -parseISO(last_modified_at ?? new Date().toISOString()).valueOf(),
    );
  }, [searchQuery]);

  const currentDemo = useMemo(() => demos.find((demo) => demo.id === demoId), [demos, demoId]);

  const helmet = (
    <Helmet>
      <title>{currentDemo ? currentDemo.title : 'Code demos'}</title>
      <meta
        name="description"
        content={
          currentDemo
            ? `${currentDemo.description}`
            : 'Code examples, mini-apps, and articles about software development.'
        }
      />
    </Helmet>
  );

  return (
    <div className="flex h-full w-full">
      {helmet}
      <nav
        className={classNames(
          !demoId ? 'flex md:px-5 lg:px-24 xl:px-48' : 'hidden lg:flex',
          'flex h-full w-[35%] grow flex-col overflow-y-scroll border-r border-gray-800',
        )}
      >
        {!demoId && <HeaderLinks as="div" />}
        <div className="mb-2 p-5">
          <H1>Demos</H1>
          <H2>Articles, mini-apps, and code examples.</H2>
        </div>
        <SearchBar targetName="a demo" onSearch={setSearchQuery} />
        {demos.map((article) => (
          <DemoThumbnail key={article.id} article={article} />
        ))}
      </nav>
      <div className={classNames('h-full w-full overflow-y-auto', demoId ? 'block' : 'hidden')}>
        <HeaderLinks as="div" />
        <ErrorBoundary FallbackComponent={ErrorPanel}>
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export function DemoThumbnail({ article }: { article: DemoArticleInfo }) {
  const locale = useLocale();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : '';
  const to = `/demos/${article.id}${queryParams}`;
  const match = useMatch({ path: `demos/${article.id}` });
  const icon = article.icon ? (
    <article.icon
      className={classNames(
        match ? 'text-blue-400' : 'text-gray-400',
        'mr-1 inline aspect-square w-4 shrink-0 align-sub',
      )}
    />
  ) : null;

  return (
    <ArticleThumbnailLayout
      titleElement={
        <div className="text-md pr-2 text-gray-400">
          {icon}
          {article.title}
        </div>
      }
      description={<p className="text-sm empty:hidden md:block">{article.description}</p>}
      date={
        <p className="text-right text-xs text-gray-500 empty:hidden">
          {renderDateCompact(article.last_modified_at, locale)}
        </p>
      }
      to={to}
    ></ArticleThumbnailLayout>
  );
}
