import { DefaultComponents } from '../components/DefaultComponents.tsx';
import HeaderLinks from './HeaderLinks.tsx';
import { Pill } from '../components/Pill.tsx';
import React, { Fragment, ReactNode } from 'react';
import { IconButton } from '../components/IconButton.tsx';
import { Link, Outlet } from 'react-router-dom';
import { FolderArrowDownIcon } from '@heroicons/react/20/solid';
import { useLocale } from '../data/Locale.ts';
import GithubIcon from '../icons/GithubIcon.tsx';
import FiveHundredPxIcon from '../icons/500pxIcon.tsx';
import { autoColor } from '../utils/ColorUtils.ts';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import useAnimatedText from '../hooks/UseAnimatedText.ts';
import ImageWithFallback from '../components/images/ImageWithFallback.tsx';
import Spinner from '../components/Spinner.tsx';
import { Helmet } from 'react-helmet';

function CrewHome() {
  return (
    <div className={'flex h-full w-full flex-col overflow-y-auto'}>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About the people who make this site possible." />
      </Helmet>
      <HeaderLinks as="div" />
      <div className="flex grow items-center self-center p-5 md:p-10 lg:max-w-[50vw] lg:p-24">
        <div className="flex flex-col items-center justify-around gap-10 lg:flex-row">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export function LeoGUserLink() {
  const name = 'Léo G.';
  return (
    <UserLink
      url="leogons"
      name={name}
      description="The guy behind it all. Click to find out more."
      avatar="https://upcdn.io/kW15btR/user-avatar/uploads/2024/02/21/4koYbaVfA8-lg_profile_pic.jpg.crop"
      userRoles={['Web Dev', 'Game Dev', 'Graphic Designer', 'Photographer ', 'Musician']}
    />
  );
}

export function LeoGUserCard() {
  const locale = useLocale();
  const label = locale.toLowerCase().includes('us') ? 'Resume' : 'CV';
  const bio = useAnimatedText({
    text: 'Programmer and design aficionado, developing primarily in `React` / `C#`, passionate about _game dev_, _web dev_, and solving ~stupid~ complex problems.\n\nWith half a decade of experience developing software and possessing the invaluable skill of knowing **Reptilia** by _the Strokes_ by heart on the guitar, bass and drums (maybe even singing the harmony), I am ready to take on any creative challenge. Even if said challenge is to animate markdown text to display as my **_bio_**.\n\nGot any questions? [Ask away](/contact)!',
    delay: 35,
  });

  return (
    <UserProfile
      name="Léo G."
      avatar="https://upcdn.io/kW15btR/user-avatar/uploads/2024/02/21/4koYbaVfA8-lg_profile_pic.jpg.crop"
      userRoles={['Web Dev', 'Game Dev', 'Graphic Designer', 'Photographer ', 'Musician']}
      bio={bio}
      links={
        <>
          <Link to="https://drive.google.com/uc?export=download&id=1DwNJL1LJEuAVrTVA0Uffevgc0SU92r1h">
            <IconButton Icon={FolderArrowDownIcon} size="SM">
              {label}
            </IconButton>
          </Link>
          <Link target="_blank" to="https://github.com/forloopcowboy" rel="noreferrer">
            <IconButton Icon={GithubIcon} size="SM">
              Main
            </IconButton>
          </Link>
          <Link target="_blank" to="https://github.com/lcgonsalves" rel="noreferrer">
            <IconButton Icon={GithubIcon} size="SM">
              Personal
            </IconButton>
          </Link>
          <Link target="_blank" to="https://500px.com/p/leocamposvieira?view=photos" rel="noreferrer">
            <IconButton Icon={FiveHundredPxIcon} size="SM">
              Photography
            </IconButton>
          </Link>
        </>
      }
    />
  );
}

function UserLink({
  name,
  description,
  avatar,
  userRoles,
  url,
}: {
  url: string;
  name: string;
  description: string;
  avatar: string;
  userRoles: string[];
}) {
  return (
    <Link
      to={url}
      className="rounded-xl border border-transparent bg-gray-800/50 p-5 transition-all duration-300 hover:border-gray-600 hover:bg-gray-800"
    >
      <div className="grid grid-cols-[3fr_1fr] gap-5">
        <div>
          <p className="mb-3">
            <span className="mr-2 text-xl text-white">{name}</span>
            <span className="text-md italic text-gray-500">{description}</span>
          </p>
          <div className="grid gap-1">
            {userRoles.map((role) => (
              <Pill key={role} classname="font-semibold text-xs px-5" bgColor={autoColor(role + 'flc')} label={role} />
            ))}
          </div>
        </div>
        <div>
          <ImageWithFallback
            fallback={
              <Spinner
                style={{
                  aspectRatio: '1/1',
                }}
                delay={500}
                className="grow items-center justify-center"
                text="Loading..."
              />
            }
            src={avatar ?? `https://api.dicebear.com/7.x/avataaars/svg?seed=${name}`}
            alt="User avatar"
            className="aspect-square min-h-[192px] min-w-[192px] max-w-48 rounded-full bg-gray-800"
          />
        </div>
      </div>
    </Link>
  );
}

function UserProfile({
  name,
  userRoles,
  bio,
  avatar,
  links,
}: {
  name: string;
  userRoles: string[];
  bio: string;
  avatar?: string;
  links?: ReactNode;
}) {
  const { h2: H1 } = DefaultComponents;

  return (
    <div className="flex flex-col items-center gap-5">
      <div className="self-stretch">
        <ImageWithFallback
          fallback={
            <Spinner
              style={{
                aspectRatio: '1/1',
              }}
              delay={500}
              className="grow items-center justify-center"
              text="Loading..."
            />
          }
          src={avatar ?? `https://api.dicebear.com/7.x/avataaars/svg?seed=${name}`}
          alt="User avatar"
          className="aspect-square max-w-48 rounded-full bg-gray-800"
        />
      </div>
      <div className="flex flex-col gap-1 p-5 md:p-1">
        <H1>{name}</H1>
        <div className="flex flex-wrap gap-2 py-4">
          {userRoles.map((role) => (
            <Pill key={role} classname="font-semibold" bgColor={autoColor(role + 'flc')} label={role} />
          ))}
        </div>
        <Markdown
          className="my-1 min-h-48 text-white md:w-[40rem]"
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSanitize]}
          components={DefaultComponents}
        >
          {bio}
        </Markdown>
        <div className="flex items-center">{links}</div>
      </div>
    </div>
  );
}

export default CrewHome;
