import { LandingPage } from '../components/LandingPage';
import '../output.css';
import 'react-toastify/dist/ReactToastify.css';
import HeaderLinks from './HeaderLinks';
import React from 'react';
import { Helmet } from 'react-helmet';

export function AppHome() {
  return (
    <div className="h-full w-full" style={{ fontFamily: 'system-ui, sans-serif', lineHeight: '1.8' }}>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="A one-man software development team" />
      </Helmet>
      <LandingPage>
        <HeaderLinks size="XL" />
      </LandingPage>
    </div>
  );
}
