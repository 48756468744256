import HeaderLinks from './HeaderLinks.tsx';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ContactHome() {
  return (
    <div className={'flex h-full w-full grow flex-col'}>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Want to know more, or request a quote on a project? Contact us!" />
      </Helmet>
      <HeaderLinks as="div" />
      <div className="my-2 flex grow flex-col gap-2 p-5 px-10 md:px-24 lg:px-40">
        <Outlet />
      </div>
    </div>
  );
}

export default ContactHome;
